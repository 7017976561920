import {HTTP_CLIENT} from '../utils/interceptor'
import {Endpoint_registrationApi} from '../utils/endpoint'

const registrationApi = async (obj)=>{
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log(obj,"puraaa object")
      const body = JSON.stringify(obj); 
    return HTTP_CLIENT.post(Endpoint_registrationApi.registrationApi,body,config)
   
}
export {
    registrationApi
}

