import "./assets/css/App.css";
import Login from "./pages/acounts/login";
import Notify from "./shared/utils/notify";
import Home from "./pages/dashboard/Home";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Signup from "./pages/acounts/Signup";
import "react-toastify/dist/ReactToastify.css";
import UsersTable from "./shared/components/users/UsersTable";
import ReportsTable from "./shared/components/reports/ReportsTable";
import ProductsTable from "./shared/components/products/ProductsTable";
import Dash from "./shared/components/main dashbord/Dash";
import Brandstable from "../src/shared/components/Brands/Brandstable";
import FeedBackReport from "./shared/components/FeedBack/FeedBackReport";
import Childtable from "./shared/components/ChildCategory/Childtable";
import SubchildTable from "./shared/components/SubChild/SubchildTable";

function App() {
  const count = useSelector((state) => state.user.user);
  return (
    <div className="App">
      {/* {console.log(count.token ,"checkkk redu")} */}
      <Routes>
        <Route
          path="/"
          element={
            count ? <Navigate replace to="/dashboard/index" /> : <Login />
          }
        />
        {/* <Route path="/signup" element={<Signup />} /> */}
        {count ? (
          <Route path="/dashboard" element={<Home />}>
            <Route path="index" element={<Dash />} />
            <Route path="users" element={<UsersTable open={true} />} />
            <Route path="category" element={<ReportsTable open={true} />} />
            <Route path="subCategory" element={<Brandstable open={true} />} />
            <Route path="childCategory" element={<Childtable open={true} />} />
            <Route path="SubchildCategory" element={<SubchildTable open={true} />} />
            <Route path="reports" element={<FeedBackReport open={true} />} />
            <Route path="products" element={<ProductsTable  open={true} />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
      <Notify />
    </div>
  );
}

export default App;
