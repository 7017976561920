import React, { useState, useEffect } from "react";
import axios from "axios";
import { Field, Form, Formik, withFormik } from "formik";
import * as yup from "yup";
import { notify } from "../../../shared/utils/notify";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../../assets/css/Auth.css";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import { registrationApi } from "../../services/RegistrationApi";
import PhoneInput from "react-phone-input-2";

const LoginValidation = yup.object().shape({
  email: yup.string().email().required("Required"),
  password: yup
    .string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});
const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  address: "",
  password: "",
};

function AddUser(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [must1, setmust1] = useState();
  const [phoneNumberall, setphoneNumberall] = useState("");
  const [Countryname, setCountryname] = useState();
  const [Countrycode, setCountrycode] = useState();

  console.log(Countrycode, phoneNumberall, "loooooo");
  const signUpHandle = async (obj) => {
    registrationApi(obj)
      .then(({ data }) => {
        if (data) {
          setOpen(false);   
          props.data(obj)
          console.log(props.data); 
          notify(`Thank you !`, "success");
          clossee();
          
        }
      })
      .catch((e) => {
        console.log(e.response,"errorr");
        if(e.response.data.code == 404){
          notify(e.response.data.message);
          setOpen(false);
        }

        if (e.response.data.message === "Email already taken") {
          notify(`Email already taken!`);
          setOpen(false);
        }
        if (
          e.response.data.message ===
          "password must contain at least 1 letter and 1 number"
        ) {
          notify(`password must contain at least 1 letter and 1 number!`);
          // setmust1("password must contain at least 1 letter and 1 number!")
          setOpen(false);
        }
      });
  };
  const { updstate } = props;
  const clossee = () => {
    updstate("New User Added");
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, action) => {
        const { name, email, phoneNumber, address, password } = values;

        let codeLenght = Countrycode.length;
        let number = phoneNumberall.substring(codeLenght);

        signUpHandle({
          name: name,
          email: email,
          phoneNumber: {
            name: Countryname,
            code: "+" + Countrycode,
            number: number,
            phoneNumber: phoneNumberall,
          },
          address: address,
          password: password,
          deviceId: "123456789",
          location: {
            coordinates: [-122.406417, 37.785834],
          },
        });
      }}
      validationSchema={LoginValidation}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className="login_bac">
          <div className="btnn adduser">
            <IconButton
              aria-label="close"
              onClick={() => {
                clossee();
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <div className="container setcontain adduuser">
            <div className="d-flex justify-content-center h-100">
              <div className="card signup">
                <div className="card-header">
                  <h3>Create User</h3>
                </div>
                <div className="card-body">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      signUpHandle();
                      setOpen(true);
                    }}
                  >
                    <div className="flexxx">
                      <div className="input-group form-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-user"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange("name")}
                          required
                        />
                      </div>
                      <div className="input-group form-group">
                        <div className="input-group-prepend phone">
                          {/* <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span> */}
                        </div>
                        <PhoneInput
                          className="phoonee1"
                          international
                          country="us"
                          isValid={(value, country) => {
                            console.log(value, country);
                            setCountrycode(country.countryCode);
                            setCountryname(country.iso2);
                          }}
                          placeholder="Enter phone number"
                          value={phoneNumberall}
                          onChange={(e) => {
                            setphoneNumberall(e);
                            console.log(e, "ppppppp");
                          }}
                        />
                        {/* <input
                        className="form-control"
                        type="text"
                        placeholder="LastName"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                        required
                      /> */}
                      </div>
                      <div className="input-group form-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-map-marker"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange("address")}
                          required
                        />
                      </div>
                      <div className="input-group form-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-user"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="User@gmail.com"
                          value={values.email}
                          onChange={handleChange("email")}
                          required
                        />
                      </div>
                      <div className="input-group form-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-key"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange("password")}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className={
                          open
                            ? "btn float-right login_btn deactive"
                            : "btn float-right login_btn"
                        }
                      >
                        {open ? (
                          <CircularProgress className="circulerr" />
                        ) : (
                          "New User"
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default AddUser;
