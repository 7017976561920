import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import { UPdateUser } from "../../services/user.service";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { notify } from "../../utils/notify";
import { fabClasses } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserUpdate(props) {
  const { updstate } = props;
  const data = props.id;
  console.log("update data", data);
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [name, setfirstName] = useState(data.name);
  const [lastName, setlastName] = useState(data.lastName);
  const [password, setpassword] = useState();
  const [phoneNumber, setphoneNumber] = useState(
    data?.phoneNumber?.phoneNumber ? data?.phoneNumber?.phoneNumber : ""
  );
  const [profillle, setprofillle] = useState(
    data.profilePic ? data.profilePic : ""
  );
  const [profileUrl, setProfileUrl] = useState(data.profilePic);
  const [updata, setUpdata] = useState(false);
  const [loader, setLoader] = useState(false);
  const [idddd, setidddd] = useState(data._id);
  const [Countryname, setCountryname] = useState();
  const [Countrycode, setCountrycode] = useState();
  console.log(Countryname, Countrycode, "sbb he");

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const uploadFiles = (e) => {
    console.log("click");
    let file = e.target.files[0];
    console.log("file", file.type.includes("image"));
    if (file.type.includes("image")) {
      if (file) {
        let url = URL.createObjectURL(file);
        setProfileUrl(url);
        setprofillle(file);
      }
    } else {
      notify("Please select just image type", "error");
    }
  };
  let codeLenght = Countrycode?.length;
  console.log(codeLenght, "lenghttt code");

  const handleSubmit = () => {
    let codeLenght = Countrycode.length;
    let number = phoneNumber.substring(codeLenght);
    console.log(number, "kalalalal");

    let obj = {
      name: name,
      profilePic: profillle,
    };
    if (phoneNumber != "") {
      obj["phoneNumber"] = JSON.stringify({
        name: Countryname,
        code: "+" + Countrycode,
        number: number,
        phoneNumber: phoneNumber,
      });
    }
    if (password) {
      obj["password"] = password;
    }
    UPdateUser(data?._id, obj)
      .then(({ data }) => {
        console.log("data submit", data);
        if (data.status) {
          updstate("Update Successfully");
          handleClose();
          let phooone = {
            name: Countryname,
            code: "+" + Countrycode,
            number: number,
            phoneNumber: phoneNumber,
          };
          props.update(idddd, obj, profileUrl, phooone);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log(profileUrl);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div className="maindivuser">
          <h2>User Data</h2>
          <hr></hr>
          {/* <form> */}
          <div className="useruPDATE">
            <div className="listingupdate">
              <TextField
                label="First Name"
                id="margin-none"
                value={name}
                onChange={(e) => {
                  setfirstName(e.target.value);
                  if (data.name != e.target.value) {
                    setUpdata(true);
                  } else {
                    setUpdata(false);
                  }
                }}
              />
            </div>
            {data.isSocial ? (
              ""
            ) : (
              <div className="listingupdate">
                <TextField
                  label="Password"
                  id="margin-none"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                    if (data.password != e.target.value) {
                      setUpdata(true);
                    } else {
                      setUpdata(false);
                    }
                  }}
                />
              </div>
            )}
            <div className="listingupdate">
              <PhoneInput
                className="phoonee1"
                international
                country="us"
                isValid={(value, country) => {
                  console.log(value, country);
                  setCountrycode(country.countryCode);
                  setCountryname(country.iso2);
                }}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => {
                  setphoneNumber(e);
                  console.log(e, "ppppppp");
                  setUpdata(true);
                }}
              />
            </div>
            <div className="listingupdate">
              <label>Profile pic</label>
              <br />
              <div className="imgflex">
                <label htmlFor="upload_image">
                  <img src={profileUrl ? profileUrl : Avatar} />
                </label>
                <input
                  type="file"
                  id="upload_image"
                  name="upload_image"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    uploadFiles(e);
                    setUpdata(true);
                  }}
                />
              </div>
            </div>
          </div>
          {updata ? (
            <button
              onClick={() => {
                handleSubmit();
                setLoader(true);
              }}
              className={
                loader
                  ? "btn float-right login_btn deactive btnupdate"
                  : "btn float-right login_btn btnupdate"
              }
            >
              {loader ? <CircularProgress className="circulerr" /> : "Update"}
            </button>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </div>
  );
}
export default UserUpdate;
