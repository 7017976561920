import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import { Outlet } from "react-router-dom";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../shared/redux/reducers/userSlie";
import Logo from "../../assets/images/logo.png";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SummarizeIcon from "@mui/icons-material/Summarize";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    if (!open) {
      setDrawerr(false);
    } else {
      setDrawerr(true);
    }
  };

  //   const list = (anchor) => (
  //     <Box
  //       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
  //       role="presentation"
  //       onClick={toggleDrawer(anchor, false)}
  //       onKeyDown={toggleDrawer(anchor, false)}
  //     >
  //       <List>
  //         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
  //           <ListItem button key={text}>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItem>
  //         ))}
  //       </List>
  //       <Divider />
  //       <List>
  //         {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //           <ListItem button key={text}>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItem>
  //         ))}
  //       </List>
  //     </Box>
  //   );
  const dispatch = useDispatch();
  const location = useLocation();
  const count = useSelector((state) => state.user.user);
  const [drawerr, setDrawerr] = useState(false);
  const [hover, setHover] = useState("Users");

  console.log("location", location);

  const [account, setAccount] = useState(false);
  const logout = () => {
    if (account) {
      setAccount(false);
    } else {
      setAccount(true);
    }
  };

  return (
    <div>
      <div className="home">
        <div className="inner">
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>
                <MenuIcon />
              </Button>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <div className="bashboard">
                  <div className="logo">
                    <Link
                      to="/dashboard/index"
                      className="lgoo"
                      role="presentation"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <img src={Logo}></img> <h4>Grew Again</h4>
                    </Link>
                  </div>

                  <div className="listing">
                    <ul>
                      <Link to="/dashboard/users">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/users"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <PersonOutlineOutlinedIcon /> Users
                        </li>
                      </Link>
                      <Link to="/dashboard/category">
                        <li
                          className={
                            location.pathname == "/dashboard/category"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <CategoryOutlinedIcon /> Categories
                        </li>
                      </Link>
                      <Link to="/dashboard/subCategory">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/subCategory"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <GraphicEqOutlinedIcon /> Sub-Category
                        </li>
                      </Link>
                      <Link to="/dashboard/childCategory">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/childCategory"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <GraphicEqOutlinedIcon /> Child-Category
                        </li>
                      </Link>
                      <Link to="/dashboard/SubchildCategory">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/SubchildCategory"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <GraphicEqOutlinedIcon /> Sub-Child
                        </li>
                      </Link>
                      <Link to="/dashboard/reports">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/reports"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <SummarizeIcon /> Reports
                        </li>
                      </Link>
                      <Link to="/dashboard/products">
                        {" "}
                        <li
                          className={
                            location.pathname == "/dashboard/products"
                              ? "hoveron"
                              : ""
                          }
                          role="presentation"
                          onClick={toggleDrawer(anchor, false)}
                          onKeyDown={toggleDrawer(anchor, false)}
                        >
                          <AddShoppingCartOutlinedIcon /> Products
                        </li>
                      </Link>
                    </ul>
                  </div>
                  {console.log(anchor, "anchorr")}
                </div>
              </SwipeableDrawer>
            </React.Fragment>
          ))}
          <div className="top_bar">
            <div className="uperbar">
              {/* <button onClick={drawerr1}>
              <PowerSettingsNewIcon />
            </button> */}
            </div>
            <div className="uperbar1">
              {/* {console.log(
              count.admin.lastName,
              count.admin.lastName,
              "counnttt"
            )}
            {count.admin.firstName}&nbsp;{count.admin.lastName} */}
              <button
                className="logoutt-btn"
                onClick={() => {
                  dispatch(resetUser());
                }}
              >
                <PowerSettingsNewIcon />
              </button>
            </div>
          </div>

          <div className={drawerr ? "Main_content mrggg" : "Main_content"}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
