import "../../../assets/css/App.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Apexxcahrt from "react-apexcharts";
import { UserapiData } from "../../services/UserApi";
import { ReportapiData } from "../../services/ReportApi";
import { ProductapiData } from "../../services/ProductApi";

function Dash() {
  const count = useSelector((state) => state.user.user);
  const [dataUser, setdataUser] = useState([]);
  const [dataReports, setdataReports] = useState([]);
  const [dataProducts, setdataProducts] = useState([]);
  const [userblock, setuserblock] = useState();
  const [userdelete, setuserdelete] = useState();
  const [reportblock, setreportblock] = useState();
  const [productblock, setproductblock] = useState();
  const [updated, setUpdated] = useState(false);

  console.log(dataUser, "aqaraayay");

  useEffect(() => {
    // Users
    UserapiData()
      .then(({ data }) => {
        if (data) {
          console.log(data,"user")
          setuserblock(data.user.users.filter((block) => block.isBlock).length);
          setuserdelete(
            data.user.users.filter((block) => block.isDelete).length
          );
          setdataUser(data.user.users);
        }
      })
      .catch(() => {});
    // Reports
    ReportapiData()
      .then(({ data }) => {
        if (data) {
          console.log(data,"cATEGORY")
          setreportblock(
            data.category.filter((block) => block.isDelete).length
          );
          setdataReports(data.category);
        }
      })
      .catch(() => {});
    // // Products

    ProductapiData()
      .then(({ data }) => {
        if (data) {
          console.log(data,"Product")

          setproductblock(
            data.product.products.filter((block) => block.isDeleted).length
          );
          setdataProducts(data.product.products);
        }
      })
      .catch(() => {});
  }, [updated]);
  const [dataApex, setdataAapex] = useState({
    series: [],

    options: {
      labels: ["User", "Block", "Delete"],
     
      colors: ['#ec8c23','#283487','#8d42a2'],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [dataApex1, setdataAapex1] = useState({
    series: [],
    options: {
      labels: ["Report", "Block"],
      colors: ['#ec8c23','#283487'],


      chart: {
        type: "donut",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [dataApex2, setdataAapex2] = useState({
    series: [],
    options: {
      labels: ["Products", "Block"],
      colors: ['#ec8c23','#8d42a2'],


      chart: {
        type: "donut",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  return (
    
    <div className="main_bar">
      <div className="heading">
        <h3>DASHBOARD</h3>
        <p>Welcome back, &nbsp;{count?.user?.name}</p>
      </div>
      <hr></hr>
      <div className="card_div">
        <div className="main1">
          <div class="cardds one">
            <div className="title">
              <h4>Users</h4>
              <i class="far fa-user"></i>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Registered Users : &nbsp; </label>
                <span>{dataUser?.length}</span>
              </p>
              <p>
                <label>Blocked Users : &nbsp; </label>
                {console.log(dataUser.filter((block) => block?.isBlock).length)}

                <span>{dataUser.filter((block) => block?.isBlock).length}</span>
              </p>
{/* 
              <p>
                <label>Deleted Users : &nbsp; </label>
                <span>
                  {dataUser.filter((deleted) => deleted.isDelete).length}
                </span>
              </p> */}
            </div>
          </div>
          <div id="chart">
            <Apexxcahrt
              options={dataApex?.options}
              series={[dataUser?.length, userblock, userdelete]}
              type="donut"
              height={350}
            />
          </div>
        </div>
        <div className="main1">
          <div class="cardds two">
            <div className="title">
              <h4>Category</h4>
              <i class="far fa-chart-bar"></i>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Total Category : &nbsp; </label>
                <span>{dataReports.length}</span>
              </p>
              <p>
                <label>Blocked Category : &nbsp; </label>
                <span>
                  {dataReports.filter((block) => block.isDelete).length}
                </span>
              </p>
            </div>
          </div>
          <div id="chart">
            <Apexxcahrt
              options={dataApex1.options}
              series={[dataReports.length, reportblock]}
              type="donut"
              height={350}
            />
          </div>
        </div>
        <div className="main1">
          <div class="cardds three">
            <div className="title">
              <h4>Products</h4>
              <i class="fad fa-archive"></i>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Total Products : &nbsp; </label>
                <span>{dataProducts.length}</span>
              </p>
              <p>
                <label>Blocked Products : &nbsp; </label>
                <span>
                  {" "}
                  {dataProducts.filter((verify) => verify.isDeleted).length}
                </span>
              </p>
            </div>
          </div>
          <div id="chart">
            <Apexxcahrt
              options={dataApex2.options}
              series={[dataProducts.length, productblock]}
              type="donut"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dash;
