import { createSlice } from "@reduxjs/toolkit";

const initState = { user: null,userSignup: null };

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSignupUser: (state, action) => {
        state.userSignup = action.payload;
      },
      resetUser: () => initState,

  },
});

export const { setUser,setSignupUser,resetUser } = userSlice.actions;

export default userSlice.reducer;
