import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserView(props) {
  const data = props.resp;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div class="scrollbar scrollbar-lady-lips">
          <div class="force-overflow">
            <div className="lelel">
              <h2>User Detail</h2>
              <div className="flexxview">
                <div className="profile">
                  <img
                    src={
                      data.profilePic
                        ? data.profilePic 
                        : Avatar
                    }
                  />
                </div>
                <div className="detaill">
                  <hr />
                  <div className="reportview">
                    <h6>Name</h6>
                    <p>{data.name}</p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6>Address</h6>
                    <p>{data.address}</p>
                  </div>
                  <hr />
                  {/* <div className="reportview">
                    <h6>Last Name</h6>
                    <p>{data.lastName}</p>
                  </div>
                  <hr /> */}
                  <div className="reportview">
                    <h6>Phone Number</h6>
                    <p>{data?.phoneNumber?.phoneNumber}</p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6>User Blocked </h6>
                    <p>{data.isBlock ? "Yes" : "No"} </p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6>User Delete</h6>
                    <p>{data.isDelete ? "Yes" : "No"}</p>
                  </div>
                  {/* <hr />
                  <div className="reportview">
                    <h6>Email Verified</h6>
                    <p>{data.isEmailVerified ? "Yes" : "No"}</p>
                  </div> */}
                  <hr />
                  <div className="reportview">
                    <h6>Social Login</h6>
                    <p>{data.isSocial ? "Yes" : "No"}</p>
                  </div>
                  
                  {/* <hr />
                  <div className="reportview">
                    <h6>Device Id</h6>
                    <div className="devideiid">
                      <p>{data.deviceId}</p>
                    </div>
                  </div> */}
                  <hr />
                  {/* <div className="reportview">
                    <h6>Active Hours</h6>
                    <p>
                      <span className="active-hours">Start: </span>
                      {moment(data.activeHours.start).format("LT")} <br />
                      <span className="active-hours">End: </span>
                      {moment(data.activeHours.end).format("LT")}
                    </p>
                  </div>
                  <hr /> */}
                  {/* <div className="reportview">
                    <h6>Daily Report Time</h6>
                    <p>
                      <span className="active-hours">Time: </span>
                      {moment(data.emailHours.time).format("LT")} <br />
                      <span className="active-hours">Alert: </span>
                      {data.emailHours.alert ? "Yes" : "No"}
                    </p>
                  </div> */}
                  <hr />
                  {/* <div className="reportview">
                    <h6>Active days</h6>
                    <div className="flexxiidays">
                      {data.activeDays.map((i) => {
                        return (
                          <>
                            <div className="bioflex">
                              <h6 className="inerflexi">{i.name}</h6>
                            </div>
                            <div className="bioflex">
                              <span className="inerflexi">
                                {i.alert ? "Yes" : "No"}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default UserView;
