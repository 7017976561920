import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useSelector } from "react-redux";
import { userApi_delete } from "../../services/User_api_delete";
import { brands_delete } from "../../services/Brands_delete";
import { category_delete } from "../../services/Report_api_block";
import Category from "./ReportsTable";
import { Childelete } from "../../services/ChildApiiAll";
import { SubChildDeleteapi } from "../../services/Sub_Child";

export default function AlertDialog(props) {

  const { updstate } = props;
  const [open, setOpen] = React.useState(true);
  const [dataUser, setdataUser] = useState([]);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  
       
  const loGinHandle = () => {
      category_delete(props.id)
      .then(({ data }) => {
        updstate("Delete Successfully");
        props.delete(props.id); 
      })
      .catch((err) => { 

      });
  };

const loGinHandle1 = () => {
  console.log(props.id,"idddd kidr h")
  brands_delete(props.id)
  .then(({ data }) => {
    updstate("Delete Successfully");
    props.delete(props.id); 
  })
  .catch((err) => { 

  });
};

const loGinHandle2 = () => {
    Childelete(props.id)
  .then(({ data }) => {
    updstate("Delete Successfully");
    props.delete(props.id); 
  })
  .catch((err) => { 

  });
};
const loGinHandle3 = () => {
  SubChildDeleteapi(props.id)
.then(({ data }) => {
  updstate("Delete Successfully");
  props.delete(props.id); 
})
.catch((err) => { 

});
};

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete !!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            onClick={() => {
              if(props.module == "brands"){
                handleClose();
                loGinHandle1();
              }
              else if(props.module == "child"){
                handleClose();
                loGinHandle2();
              }
              else if(props.module == "subchild"){
                handleClose();
                loGinHandle3();
              }


              else{
                handleClose();
              loGinHandle();

              }
             
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
